(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/context-menu/assets/javascripts/context-menu-button.js') >= 0) return;  svs.modules.push('/components/tipsen/context-menu/assets/javascripts/context-menu-button.js');
"use strict";

const {
  ReactButton,
  ReactIcon
} = svs.ui;
const ContextMenuButton = _ref => {
  let {
    children,
    'data-testid': dataTestId,
    icon,
    isDisabled = false,
    onClick,
    size = 100
  } = _ref;
  return React.createElement(ReactButton, {
    className: "f-left",
    "data-testid": dataTestId,
    disabled: isDisabled,
    iconPosition: "left",
    onClick: onClick,
    size: size,
    transparent: true
  }, React.createElement(ReactIcon, {
    icon: icon,
    size: "".concat(size)
  }), children ? children : null);
};
setGlobal('svs.components.tipsen.contextMenu.ContextMenuButton', ContextMenuButton);

 })(window);