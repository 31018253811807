(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/context-menu/assets/javascripts/context-menu.js') >= 0) return;  svs.modules.push('/components/tipsen/context-menu/assets/javascripts/context-menu.js');
"use strict";

const {
  useMemo
} = React;
const {
  default: TippyReact
} = Tippy;
const {
  ContextMenuButton
} = svs.components.tipsen.contextMenu;
const ContextMenu = _ref => {
  let {
    choices,
    children,
    'data-testid': dataTestId,
    hideContextMenu,
    isOpen,
    showContextMenu,
    size = 100
  } = _ref;
  const MenuContent = useMemo(() => React.createElement("div", {
    className: "context-menu-content"
  }, choices && choices.map(choice => React.createElement(ContextMenuButton, {
    "data-testid": choice['data-testid'],
    icon: choice.icon,
    isDisabled: choice.isDisabled,
    key: "".concat(choice.icon, "-").concat(choice.title),
    onClick: () => {
      choice.onClick();
      hideContextMenu();
    },
    size: size
  }, choice.title))), [choices, hideContextMenu, size]);
  if (choices.length < 1) {
    return null;
  }
  return React.createElement("div", {
    className: "context-menu"
  }, React.createElement(TippyReact, {
    content: MenuContent,
    interactive: true,
    onClickOutside: hideContextMenu,
    placement: "bottom-end",
    theme: "light-border",
    trigger: "manual",
    visible: isOpen
  }, React.createElement("button", {
    "aria-haspopup": "menu",
    className: "context-menu-button",
    "data-testid": dataTestId,
    onClick: showContextMenu,
    type: "button"
  }, children)));
};
setGlobal('svs.components.tipsen.contextMenu.ContextMenu', ContextMenu);

 })(window);